export default function submenu() {
  let subMenu = document.getElementById("list-sub_menu");
  let drop = document.getElementById("menu-item-30");
  let lang = document.querySelector(".switcher-list");

  if (subMenu) {
    let s = document.createElement("span");
    s.classList.add("s");
    s.innerHTML =
      '<svg width="11" height="100%" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.49991 6L11 0H0L5.49991 6Z" fill="white" /></svg>';
    drop.append(subMenu);
    drop.classList.add("dropdown");
    drop.querySelector("a").append(s);
  }

  if (lang) {
    let s = document.createElement("span");
    s.classList.add("s");
    s.innerHTML =
      '<svg width="11" height="100%" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5.49991 6L11 0H0L5.49991 6Z" fill="#141F29" /></svg>';
    lang.append(s);
  }
}
