import all from "./modules/utilities";
import formSent from "./modules/form";
import changeArrow from "./modules/arrow";
import changePath from "./modules/change-path";
import onScroll from "./modules/onscroll";
import submenu from "./modules/submenu";

document.addEventListener("DOMContentLoaded", (e) => {
  const check_posts = document.getElementById("chech_posts");
  const { link, posts } = check_posts.dataset;
  const canonical = document.querySelectorAll("link[rel='canonical']");

  canonical.length && canonical.forEach((l) => (l.href = location.href));

  if (posts == "false") {
    const pageNews = document.querySelectorAll(`a[href='${link}']`);
    pageNews.forEach((a) => a.parentNode.remove());
  }
  check_posts.remove();

  formSent();
  changeArrow();
  changePath();
  onScroll();
  submenu();

  // open/close lang
  // let lang = document.querySelector('.lang-wrap');
  // lang.addEventListener('click', e=>{
  //     lang.classList.toggle('active')
  // });
  // document.addEventListener('click', function(e) {
  //     if (!lang.contains(e.target)) lang.classList.remove('active');
  // });
});
