export default function changeArrow() {
  let split = document.querySelectorAll(".split_arr");
  let remove = document.querySelectorAll(".remove_arr, .post-page, head title");
  let numAll = document.querySelectorAll(".num_arr");

  [...split].forEach((text) => {
    let t = text.textContent.split("*");
    text.innerHTML = t[0] + `<span>${t[1]}</span>`;
    text.classList.remove("split_arr");
  });

  [...remove].forEach((text) => {
    text.innerHTML = text.textContent.split("*").join("");
    text.classList.remove("remove_arr");
  });

  [...numAll].forEach((text) => {
    let num = text.querySelector(".num-icon");
    let d = document.createElement("div");
    let l = document.createElement("div");
    let t = text.textContent.trim().split(" ");
    let f = t.shift();
    text.innerHTML = " ";
    d.classList.add("d");
    l.classList.add("l");
    text.append(d);
    text.append(l);
    text.querySelector('.d').innerHTML = `<span class='${num.classList.value}'></span>${f}`;
    text.querySelector('.l').innerText = " " + t.join(" ");

  });
}
