import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const openMenu = () => {
  disableBodyScroll(document.body, { reserveScrollBarGap: true });
};
const closeMenu = () => {
  enableBodyScroll(document.body, { reserveScrollBarGap: true });
};

export default function formSent() {
  let form = document.querySelectorAll("form");
  let buttons = document.querySelectorAll("[data-f-button]");
  let popUps = document.querySelectorAll(".form_pop-up");

  if (!form) return;

  buttons.forEach((b) => {
    let c = b.dataset.close;
    let o = b.dataset.open;
    c && closeForm(b);
    o && openForm(b); 
  });

  function closeForm(b) {
    b.addEventListener("click", (e) => {
      [...popUps].forEach((p) => p.classList.remove("active", "sent"));
      closeMenu();
    });
  }
  function openForm(b) {
    let p = [...popUps].find((p) => p.classList.contains(b.dataset.open));
    let t = b.dataset.title;
    b.addEventListener("click", (e) => {
      p.classList.add("active");
      openMenu();
      t && changeTitle(p, t);
    });
  }

  function changeTitle(p, t) {
    let title = p.querySelector(".form_block .title span");
    let value = p.querySelector('input[name="package-title"]');
    title.innerHTML = "“" + t + "”";
    value.value = t;
  }

  form.forEach((f) => {
    let submitB;

    f.addEventListener("submit", (e) => {
      submitB = f.querySelector('[type="submit"]') || e.submitter;
      submitB.disabled = true;
    });
    f.addEventListener("wpcf7submit", (e) => {
      submitB.disabled = false;
    });
    f.addEventListener("wpcf7mailsent", (e) => {
      submitB.disabled = false;
      let p = f.closest(".form_pop-up");

      if (!p) {
        p = popUps[0];
        p.classList.add("active");
      }
      p.classList.add("sent");
      p.querySelector(".success_message h3").innerHTML =
        e.detail.apiResponse.message;
    });
  });
}
